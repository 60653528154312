const form = document.getElementById("js-contact-form");
const info = document.getElementById("js-contact-form-info");
const error = document.getElementById("js-contact-form-error");
const submit = document.getElementById("js-submit");

form.addEventListener("submit", event => {
    event.preventDefault();
	
	const formData = new FormData(form)
    info.innerHTML = "";
    error.innerHTML = "";
    submit.disabled = true;

    fetch('/mail.php', {
        method: "post",
        body: formData,
    })
        .then(response => response.json())
        .then(data => {
            if(data.message) {
                info.innerHTML = data.message;
                form.reset();
            }
            else if (data.error) {
                error.innerHTML = data.error;
                submit.disabled = false;
            }
        });
})